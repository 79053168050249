import React from 'react';
import { useState } from "react";
import QrContainer from "./QrContainer.js";



export default function App() {

  const queryParams = new URLSearchParams(window.location.search)
  const thisid = queryParams.get("id")
  console.log("querystring:"+thisid);
  
  const attendees = [ 
	  {
		id: '68d7c',
		name: 'Heng Koon How',
	  },
	  {
		id: '7b8d5',
		name: 'Morgan Chia'
	  },
	  {
		id: '96996',
		name: 'Nikhil Chogle',
	  },
	  {
		id: '89aee',
		name: 'Chua Wan An',
	  },
	  {
		id: 'de75c',
		name: 'Yvonne Liang',
	  },
  ];


  const [pgNum, setPgNum] = useState(0);
  const [dollar1Count, setDollar1Count] = useState(2);
  const [dollar2Count, setDollar2Count] = useState(1);
  const [dollar5Count, setDollar5Count] = useState(2);
  const [pinCount, setPinCount] = useState(0);


	// https://bobbyhadz.com/blog/react-pass-data-from-child-to-parent
	const handleCallback = (data) => {
		// Update the name in the component's state
	//        this.setState({ scandata: data }) 
		//alert('QRCode scan data='+data);
		console.log('QRCode scan data='+data);
		
		//GotoNextPg
		//setPgNum( pgNum +1 );
		setBgImgName(bgImg[pgNum +1]);
		setPgNum( pgNum => pgNum +1 );
		console.log('pgNum='+ (pgNum +1) );
	}


  const bgImg = ["/p2.jpg", "/p3.jpg", "/q1.jpg", "/q2.jpg", "/q3x.jpg","/q4.jpg","","/q6.jpg","/q7.jpg","/q8.jpg","/q9.jpg","/q1a.jpg"];
  const dots = ["/q7_0_dot.jpg","/q7_1_dot.jpg","/q7_2_dot.jpg","/q7_3_dot.jpg","/q7_4_dot.jpg","/q7_5_dot.jpg","/q7_6_dot.jpg"];
  const [bgImgName, setBgImgName] = useState(bgImg[pgNum]);

	const myStyle={
		backgroundImage: `url(${bgImgName})`,
		height: '100vh',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		overflow: 'auto',
	};

  const img1finance = {
    position: 'absolute',
    bottom: '0px',
    width: '13%',
    border: 'solid 3px red',
    opacity: 0,  // transparent
  };
  const img2finance = {
    position: 'absolute',
    left: '125px',
    bottom: '25px',
    width: '17%',
    border: 'solid 3px red',
    opacity: 0,  // transparent
  };
  const img3finance = {
    position: 'absolute',
    right: '25px',
    bottom: '25px',
    width: '45%',
    border: 'solid 3px red',
    opacity: 0,  // transparent
  };
  const img4finance = {
    position: 'absolute',
    left: '20px',
    top: '80px',
    width: '37%',
    border: 'solid 3px red',
    opacity: 0,  // transparent
  };
  const img5_q3a_1dollar = {
    position: 'absolute',
    left: '23px',
    top: '85px',
    width: '40%',
    border: 'solid 3px red',
    opacity: 0,  // transparent
  };  
  const img5_q3a_2dollar = {
    position: 'absolute',
    left: '205px',
    top: '85px',
    width: '40%',
    border: 'solid 3px red',
    opacity: 0,  // transparent
  };
  const img5_q3a_5dollar = {
    position: 'absolute',
    left: '23px',
    top: '314px',
    width: '40%',
    border: 'solid 3px red',
    opacity: 0,  // transparent
  };
  const img5_q3x_continue = {
    position: 'absolute',
    left: '0px',
    bottom: '0px',
    width: '100%',
//    border: 'solid 3px red',
    opacity: 0,  // transparent
  };
  const img6finance = {
    position: 'absolute',
    left: '20px',
    bottom: '180px',
    width: '88%',
    border: 'solid 3px red',
    opacity: 0,  // transparent
  };
  const img8finance = {
    position: 'absolute',
    left: '22px',
    bottom: '26px',
    width: '87%',
    border: 'solid 3px red',
    opacity: 0,  // transparent
  };
  const img9afinance = {
    position: 'absolute',
    left: '0px',
    bottom: '115px',
    width: '100%',
//    border: 'solid 3px red',
    opacity: 0,  // transparent
  };
  const img9bfinance = {
    position: 'absolute',
    right: '135px',
    bottom: '47px',
    width: '34%',
//    border: 'solid 3px red',
    opacity: 0,  // transparent
  };
  const img9cfinance = {
    position: 'absolute',
    right: '0px',
    bottom: '47px',
    width: '35%',
//    border: 'solid 3px red',
    opacity: 0,  // transparent
  };
  const img9_dots = {
    position: 'absolute',
    right: '0px',
    top: '0px',
    width: '100%',
    //opacity: 0,  // transparent
  };
  const img10_great = {
    position: 'absolute',
    right: '15px',
    bottom: '20px',
    width: '90%',
//    border: 'solid 3px red',
    opacity: 0,  // transparent
  };

  const containerPg1 = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100hh',
  };

  const containerPg6 = {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
	height: '100%',
  };

  const GotoNextPg =  event => {
    console.log('Now in page:'+pgNum);

    setBgImgName(bgImg[pgNum+1]);
    setPgNum( pgNum +1 );
    console.log('Going to page:'+pgNum);

    console.log(event.target);
  };

  const GotoPg2 = event => {
    if ( dollar1Count > 0) {
      setPgNum( 2 );
      console.log('setPage:2');
      setBgImgName(bgImg[2]);

    }
    else {
      setPgNum( 11 );
      console.log('setPage:11');
      setBgImgName(bgImg[11]);

    }
    //setBgImgName(bgImg[pgNum]);
    //console.log(event.target);
    //console.log('Going to pagexxxx:'+pgNum);
  };


  const AddDollar1 = event => {
    dollar1Count === 0? setDollar1Count(1): setDollar1Count(0); 
    console.log(event.target);
    console.log('AddDollar:1');
  };
  const AddDollar2 = event => {
    dollar2Count === 0? setDollar2Count(1): setDollar2Count(0); 
    console.log(event.target);
    console.log('AddDollar:2');
  };
  const AddDollar5 = event => {
    dollar5Count === 0? setDollar5Count(1): setDollar5Count(0); 
    console.log(event.target);
    console.log('AddDollar:5');
  };
  const InputPin = async event => {
    
    setPinCount(pinCount + 1)

    console.log(event.target);
    console.log('InputPIN: pinCount='+pinCount);      

    if (pinCount >= 5 )
    {
      //GotoNextPg
      //setPgNum( pgNum +1 );

      await timeout(1000); //for 1 sec delay
      setBgImgName(bgImg[pgNum+1]);
      setPgNum( pgNum => pgNum +1 );
      console.log('pgNum='+pgNum +1 );


      await timeout2(5000); //for 5 sec delay, then load another page
      setPgNum( 10 );
      setBgImgName(bgImg[10]);
      console.log('pgNum=10');
      setDollar1Count(0);
      setDollar2Count(0);
      setDollar5Count(0);


    }
  };
  const InputBS = event => {
    if (pinCount > 0 )
      setPinCount(pinCount - 1); 

    console.log(event.target);
    console.log('InputBS: pinCount='+pinCount);

  };
  const Input6 = event => {
	// user input 6 digits, not we do a axios to merchant website

    console.log(event.target);
    console.log('InputBS: pinCount='+pinCount);

  };

  function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }

  function timeout2(delay) {
    return new Promise( res => setTimeout(res, delay) );
  }
  
  /*
  const handleScan = async (scanData) => {
    setLoadingScan(true);
    console.log(`loaded data data`, scanData);
    if (scanData && scanData !== "") {
      console.log(`loaded >>>`, scanData);
      setData(scanData);
      setStartScan(false);
      setLoadingScan(false);
      // setPrecScan(scanData);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };
*/
/*
	this.state = {
        scandata: "",
    }
*/
 


	return (
    <>
	  <meta name="apple-mobile-web-app-capable" content="yes" />
	  <meta name="mobile-web-app-capable" content="yes" />

      <div style={myStyle}>
      </div>    
      <div style={containerPg1}>
        {
          pgNum === 0 ? 
		  <>
            <img 
              src="/finance_icon.jpg" 
              alt="logo" 
              style={img1finance} 
              onClick={GotoNextPg}
            />
		  </>
          : 
            ""
        }
      <div>
      </div>
        {
          pgNum === 1 ? 
            <img 
              src="/p3_web3.jpg" 
              alt="logo" 
              style={img2finance} 
              onClick={GotoPg2}
            />
          : 
            ""
        }
                {
          pgNum === 2 ? 
            <img 
              src="/q1_send.jpg" 
              alt="logo" 
              style={img3finance} 
              onClick={GotoNextPg}
            />
          : 
            ""
        }
        {
          pgNum === 3 ? 
            <img 
              src="/q2_vouchers.jpg" 
              alt="logo" 
              style={img4finance} 
              onClick={GotoNextPg}
            />
          : 
            ""
        }
        {
          pgNum === 4 ? 
            (dollar1Count === 0 ? 
              <img 
                src="/q3a_1dollar.jpg" 
                alt="logo" 
                style={img5_q3a_1dollar} 
                onClick={AddDollar1}
              />
            : 
            <img 
              src="/q3a_1dollarSelected.jpg" 
              alt="logo" 
              style={img5_q3a_1dollar} 
              onClick={AddDollar1}
            />
            )
            :
            ""
        }
        {
          pgNum === 4 ? 
            ( dollar2Count === 0 ? 
              <img 
                src="/q3a_2dollar.jpg" 
                alt="logo" 
                style={img5_q3a_2dollar} 
                onClick={AddDollar2}
              />
            : 
            <img 
              src="/q3a_2dollarSelected.jpg" 
              alt="logo" 
              style={img5_q3a_2dollar} 
              onClick={AddDollar2}
            />
            )
          :
          ""
        }
        {
          pgNum === 4 ? 
            (dollar5Count === 0 ? 
              <img 
                src="/q3a_5dollar.jpg" 
                alt="logo" 
                style={img5_q3a_5dollar} 
                onClick={AddDollar5}
              />
            : 
            <img 
              src="/q3a_5dollarSelected.jpg" 
              alt="logo" 
              style={img5_q3a_5dollar} 
              onClick={AddDollar5}
            />) 
            :
            ""
        }
        {
          pgNum === 4 ?

            ! (dollar1Count === 0 && dollar2Count === 0 && dollar5Count === 0) ? 

            <img 
                src="/q3x_continue.jpg" 
                alt="continue" 
                style={img5_q3x_continue} 
                onClick={GotoNextPg}
            />
            :
            ""
          :
          ""
        }
        {
          pgNum === 5 ? 
            <img 
              src="/q4_scan.jpg" 
              alt="logo" 
              style={img6finance} 
              onClick={GotoNextPg}
            />
          : 
            ""
        }
        {
          pgNum === 6 ? 
		  <div style={containerPg6}>
				<QrContainer parentCallback={handleCallback} />
		  </div>
          : 
            ""
        }
        {
          pgNum === 7 ? 
            <img 
              src="/q6_send.jpg" 
              alt="logo" 
              style={img8finance} 
              onClick={GotoNextPg}
            />
          : 
            ""
        }
        {
          pgNum === 8 ? 
            <img 
              src="/q7_keyboard1.jpg" 
              alt="logo" 
              style={img9afinance} 
              onClick={InputPin}
            />
          : 
            ""
        }
        {
          pgNum === 8 ? 
            <img 
              src="/q7_keyboard2.jpg" 
              alt="logo" 
              style={img9bfinance} 
              onClick={InputPin}
            />
          : 
            ""
        }
        {
          pgNum === 8 ? 
            <img 
              src="/q7_keyboard_backspace.jpg" 
              alt="logo" 
              style={img9cfinance} 
              onClick={InputBS}
            />
          : 
            ""
        }
        {
          pgNum === 8 && (pinCount >= 0 && pinCount <=6) ? 
            <img 
              src={dots[pinCount]} 
              alt="logo" 
              style={img9_dots} 
            />
          : 
            ""
        }
        {
          pgNum === 10 ? 
            <img 
              src="/q9_great.jpg" 
              alt="logo" 
              style={img10_great} 
              onClick={GotoPg2}
            />
          : 
            ""
        }

      </div>
    </>	
  );
}




